import UploadFiles from "../../components/importacaoProdutos/UploadFiles.vue";
import CardImportacao from "../../components/importacaoProdutos/CardImportacao.vue";
import IdentificaImportacao from "../../components/importacaoProdutos/IdentificaImportacao.vue";
import ReviewImport from '../../components/importacaoProdutos/ReviewImport.vue';
import { tabelaProdutos } from "./tabelaProdutos.js";

export default {
  components: {
    UploadFiles,
    CardImportacao,
    IdentificaImportacao,
    ReviewImport,
  },

  data() {
    return {
      keyImportacao: 0,
      step: 1,
      nomeExcel: "",
      identificador: {},
      produtos: 0,
      attIdentificador: 0,
      dados: [],
      dialog: false,
      attUpload: 0,
      tab: 0,
      corretos: [],
      incorretos: [],
      duplicados: [],
      options: [
        { 
          id: "Corretos", 
          image: "/corretos.svg", 
          color: "#22c55e", 
          dados: [], 
        },
        {
          id: "Incorretos",
          image: "/incorretos.svg",
          color: "#ef4444",
          dados: [],
        },
        {
          id: "Duplicados",
          image: "/duplicados.svg",
          color: "#f59e0b",
          dados: [],
        },
      ],
      nomeArquivo: "SAAS - Modelo importação de produto (v.2.0.0).xlsx",
      versaoArquivo: "v.2.0.0",
      tabelaProdutosBase64: tabelaProdutos,
      titulo: "Importação de produtos",
    };
  },

  watch: {
    step(val) {
      if (val == 2) {
        this.dados = [];
        this.attUpload++;
      } 
      else if (val == 4){
        this.attIdentificador++;
      }
    },
  },

  mounted() {
    // const unique2 = this.obj.filter((obj, index) => {
    //     return !(index === this.obj.findIndex(o => obj.cd_barras == o.cd_barras));
    //   });
  },
  
  methods: {

    ultimoPasso(){
      this.step++
    },

    salvar(produto) {
      const campos = {
        Corretos: 0,
        Incorretos: 1,
        Duplicados: 2,
      };
      this.options[campos[produto.array]].dados[produto.id] = produto.prod;
    },

    identificaLote(identificacao){
      this.identificador = identificacao;
      this.step++;
    },

    mudaIdentificacao(novaIdentificacao){
      this.identificador = novaIdentificacao;
    },

    backImport() {
      this.step--;
    },

    trataImportado(dados) {
      this.dados = dados;
      this.consistir(this.dados);
    },

    consistir(dados) {
      let prods = dados;
      prods = this.verificaUpperCase(prods);
      prods = this.verificaIncorretos(prods);
      prods = this.removeDuplicata(prods);
      //prods = this.corrigir(prods);
      this.options[0].dados = prods;
    },

    removeDuplicata(produtos) {
      const prods = produtos;
      const naoDuplicado = prods.filter((obj, index) => {
        return (
          index ===
          prods.findIndex((o) => obj.barras == o.barras || obj.sku == o.sku)
        );
      });
      const duplicado = prods.filter((obj, index) => {
        return !(
          index ===
          prods.findIndex((o) => obj.barras == o.barras || obj.sku == o.sku)
        );
      });
      this.options[2].dados = duplicado;
      return naoDuplicado;
    },

    corrigir(produtos) {
      const prods = produtos;
      // this.tabelaCorrigida = this.verificaDuplicata(this.tabelaCorrigida);
      // this.validacoes(this.tabelaCorrigida);
      this.verificaSku(prods);
      // this.dados = this.corretos;
      // this.keyFlexGrid++;
      // this.nomeTab = "Todos";
      // this.tab = 0;
    },

    async verificaSku(dados) {
      let arraySku = [];
      dados.map((produto) => {
        arraySku.push({ _rCD_PRODE: produto.ce110_cd_sku });
      });
      try {
        const res = await this.postSP("SP_CE360901036", arraySku);

        res.data.database.forEach((objDB) => {
          dados.forEach((obj) => {
            if (obj.sku === objDB.ce110_cd_sku) {
              obj.status = 1;
            }
          });
        });

        window.scrollTo(0, 0);
      } catch (err) {
        // eslint-disable-next-line no-console
        this.mensagemConsole("ERRO:", err);
      }
      return dados;
    },

    limpaCampos() {
      this.dados = [];
      this.corretos = [];
      this.errados = [];
      this.duplicados = [];
      this.avisos = [];
    },

    cancelar() {
      this.limpaCampos();
      this.nomeTab = "Todos";
      this.tab = 0;
    },

    verificaUpperCase(dados) {
      let arrayDados = dados;
      arrayDados.forEach((item) => {
        item.sku = item.sku ? item.sku.toString().toUpperCase() : null;
        item.prod =
          item.prod == undefined ? null : item.prod.toString().toUpperCase();
        item.ref =
          item.ref == undefined ? null : item.ref.toString().toUpperCase();
        item.unidade =
          item.unidade == undefined
            ? null
            : item.unidade.toString().toUpperCase();
        item.ds_subgrupo =
          item.ds_subgrupo == undefined
            ? null
            : item.ds_subgrupo.toString().toUpperCase();
      });
      return arrayDados;
    },

    verificaIncorretos(dados) {
      let data = dados;
      let errados = [];
      const ver = (item) => {
        if (
          !item.barras ||
          !item.sku ||
          !item.prod ||
          !item.situac ||
          !(item.cd_grp || item.ds_grp) ||
          !item.unidade ||
          !item.custo ||
          !item.venda
        ) {
          return { ...item };
        }
      };
      errados = data.filter((f) => ver(f));
      data = data.filter((f) => !ver(f));
      this.options[1].dados = errados;
      return data;
    },

    InicializaVariaveis(param) {
      this.keyImportacao = param;
      this.step = 1;
      this.nomeExcel = "";
      this.identificador = {};
      this.produtos = 0;
      this.attIdentificador = 0;
      this.dados = [];
      this.dialog = false;
      this.attUpload = 0;
      this.tab = 0;
      this.corretos = [];
      this.incorretos = [];
      this.duplicados = [];
      this.options = [
        { 
          id: "Corretos", 
          image: "/corretos.svg", 
          color: "#22c55e", 
          dados: [], 
        },
        {
          id: "Incorretos",
          image: "/incorretos.svg",
          color: "#ef4444",
          dados: [],
        },
        {
          id: "Duplicados",
          image: "/duplicados.svg",
          color: "#f59e0b",
          dados: [],
        }];
    },

    async getProdutos() {
      let produtos = this.options[0].dados;

      produtos.forEach( (prod) => {

        try {
          prod.custo = parseFloat(prod.custo.replace(",", "."));
          prod.venda = parseFloat(prod.venda.replace(",", "."));
          prod.venant = parseFloat(prod.venant.replace(",", "."));
        } catch(e) {
          // eslint-disable-next-line no-console
          this.mensagemConsole("ERRO:", e);
        }

      });

      return produtos;
    },

    async download(conteudo){
      const nomeArquivo = "arquivo.txt";
      const blob = new Blob([conteudo], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async confirmarImportacao() {
      try {
        this.startLoader();
        let usuario = await this.getUser();
        let params = {
          cd_loja: this.identificador.loja,
          id_guid: this.uuidv4(),
          dt_hora: this.identificador.hora,
          ds_desc: this.identificador.desc,
          ds_obs:  this.identificador.obs,
          js_prod: await this.getProdutos(),
          cd_oper: usuario.base.us01_nr_usr,
        };

        let retorno = await this.request({
          metodo: "post",
          sp: "sp_ce360901038",
          params: params,
        });
        if (retorno.data.database[0].sp_ce360901038 == "OK") {
          this.notification().success({mensagem: "Produto(s) importado(s) com sucesso!"});
          this.InicializaVariaveis(this.keyImportacao++);        
          this.$router.push({ path: 'saastec-dashboard' });
        } else {
          // this.notification().error({erro: "Algo deu errado no envio do(s) produto(s). "+retorno.data.database[0].sp_ce360901038});
          this.notification().warn({mensagem: retorno.data.database[0].sp_ce360901038});
        }
      } catch (e) {
        this.notification().error({erro: "Algo deu errado no envio do(s) produto(s). "+e});
        // eslint-disable-next-line no-console
        this.mensagemConsole("ERRO: ", e);
      } finally {
        this.stopLoader();
      }      
    },

  },
};